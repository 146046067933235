import React, { Suspense, lazy } from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/shared/Layout/Layout";
import CircularProgress from "@mui/material/CircularProgress";
const HomeComponent = lazy(() => import("./components/home/Home"));
const AboutUs = lazy(() => import("./components/aboutUs/AboutUs"));
const MainServices = lazy(
  () => import("./components/services/MainServicesPage")
);
const VedicKundali = lazy(() => import("./components/services/VedicKundali"));
const VastuVisit = lazy(() => import("./components/services/VastuVisit"));
const Gemstone = lazy(() => import("./components/services/Gemstone"));
const VarshikFal = lazy(() => import("./components/services/VarshikFal"));
const KundaliMilan = lazy(() => import("./components/services/KundaliMilan"));
const Predictions = lazy(() => import("./components/services/Predictions"));
const Contact = lazy(() => import("./components/contactus/Contact"));

const App = () => {
  return (
    <Suspense
    
      fallback={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={50} sx={{ color: "#F4A636" }} />
        </Box>
      }
    >
      <Router>
        <Layout>
          <Helmet>
            <title>
              Astrolohani - Your Gateway to Vedic Astrology and Spiritual
              Insights
            </title>
            <meta
              name="description"
              content="Experience the transformative power of Vedic astrology at astrolohani. Get insights into your life's journey, improve spaces with Vastu expertise, discover the radiance of gemstones, navigate the year ahead with annual predictions, and find lasting relationships through horoscope matching."
            />
            <meta
              name="keywords"
              content="astrolohani, best astrologer in rudrapur , best astrologer in uttrakhand, pandit lalit lohani,Vedic astrology, astrology services, horoscope matching, gemstone recommendations, Vastu Shastra, annual predictions, astrologer in Rudrapur, Kundali analysis, cosmic guidance, spiritual insights"
            />
            <meta
              property="og:title"
              content="Astrolohani - Your Trusted Astrologer"
            />
            <meta
              property="og:description"
              content="Unlock the secrets of your life's journey with personalized Vedic Kundali charts."
            />
            <meta
              property="og:image"
              content="https://cdn.astrolohani.com/astrolohani/astrolohani-og-image.png"
            />
            <link rel="preload" href="/path/to/critical.css" as="style" />
            <link
              rel="preload"
              href="/path/to/font.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              href="https://cdn.astrolohani.com/astrolohani/astrolohani-og-image.png"
              as="image"
            />
          </Helmet>
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services" element={<MainServices />} />
            <Route path="/services/vedic-kundali" element={<VedicKundali />} />
            <Route path="/services/vastu-visit" element={<VastuVisit />} />
            <Route path="/services/gemstone" element={<Gemstone />} />
            <Route path="/services/varshik-fal" element={<VarshikFal />} />
            <Route path="/services/kundali-milan" element={<KundaliMilan />} />
            <Route path="/services/predictions" element={<Predictions />} />
            <Route path="/contact-us" element={<Contact />} />
          </Routes>
        </Layout>
      </Router>
    </Suspense>
  );
};

export default App;
